import PlacesAutocomplete from "stimulus-places-autocomplete"
import { Loader } from "@googlemaps/js-api-loader"

export default class extends PlacesAutocomplete {
  static values = { apiKey: String }

  connect() {
    const loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "quarterly",
      libraries: ["places"],
    })

    loader
      .load()
      .then(this.initAutocomplete.bind(this))
      .catch((e) => {
        console.log(e)
        console.log("error loading Google Maps")
      })
  }

  initAutocomplete(google) {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.addressTarget,
      this.autocompleteOptions,
    )
    this.autocomplete.addListener("place_changed", this.placeChanged)
  }
}
