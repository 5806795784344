import ApplicationController from "./application_controller"
import { DirectUpload } from "@rails/activestorage"
import { Dropzone } from "dropzone"
import prettyBytes from "pretty-bytes"

export default class extends ApplicationController {
  static targets = [
    "input",
    "uploader",
    "progress_bar",
    "filesize",
    "filename",
    "progressbar",
    "error",
    "uploadmessage",
    "progress",
    "progressbadge",
    "spinner",
  ]
  static values = { validation: Object }

  connect() {
    super.connect()
    this.dropZone = this.createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
    Dropzone.autoDiscover = false
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      this.progressTarget.innerHTML = "0%"
      this.uploadmessageTarget.innerHTML = "Subiendo..."
      this.progressbadgeTarget.classList.add("text-blue-800", "bg-blue-100")
      this.progressbadgeTarget.classList.remove(
        "text-green-800",
        "bg-green-100",
      )
      this.spinnerTarget.classList.remove("fa-regular", "fa-check")
      this.spinnerTarget.classList.add(
        "fa-duotone",
        "fa-spinner-third",
        "fa-spin",
      )
      setTimeout(() => {
        file.accepted && this.createDirectUploadController(this, file).start()
      }, 500)
    })

    this.dropZone.on("removedfile", (file) => {
      if (file.controller) {
        this.removeElement(file.controller.hiddenInput)
        this.uploaderTarget.style.display = "block"
        this.progress_barTarget.style.display = "none"
      }
    })

    this.dropZone.on("canceled", (file) => {
      if (file.controller) {
        file.controller.xhr.abort()
        this.uploaderTarget.style.display = "block"
        this.progress_barTarget.style.display = "none"
      }
    })
  }

  get headers() {
    return { "X-CSRF-Token": this.getMetaValue("csrf-token") }
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256
  }

  get dictFileTooBig() {
    return (
      this.data.get("dictFileTooBig") ||
      "El tamaño del archivo es {{filesize}} pero solo se permiten archivos de hasta {{maxFilesize}}"
    )
  }

  get dictInvalidFileType() {
    return this.data.get("dictInvalidFileType") || "Tipo de archivo no válido"
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles")
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true
  }

  getMetaValue(name) {
    const element = this.findElement(document, `meta[name="${name}"]`)
    if (element) {
      return element.getAttribute("content")
    }
  }

  findElement(root, selector) {
    if (typeof root == "string") {
      selector = root
      root = document
    }
    return root.querySelector(selector)
  }

  removeElement(el) {
    if (el && el.parentNode) {
      el.parentNode.removeChild(el)
    }
  }

  createDirectUploadController(source, file) {
    return new DirectUploadController(source, file)
  }

  cancel() {
    this.dropZone.removeAllFiles()
    this.stimulate("AplazoSubmission#remove_document")
  }

  addedFile(file) {
    this.filenameTarget.innerHTML = file.name
    this.filesizeTarget.innerHTML = prettyBytes(file.size)
    // let uploadEl = this.findElement(this.element, ".dz-upload")
    // uploadEl.classList.add("bg-blue-600")
    // uploadEl.classList.remove("bg-teal-500")
    this.uploaderTarget.style.display = "none"
    this.progress_barTarget.style.display = "block"
    // this.findElement(this.element, ".error-icon").style.display = "hidden";
    this.findElement(this.element, ".dropzone-msg").classList.remove(
      "uploader-error",
    )
    this.findElement(this.element, ".error-message").classList.add("hidden")
  }

  successful(file) {
    this.findElement(this.element, ".dropzone-msg").classList.remove(
      "uploader-error",
    )
    this.findElement(this.element, ".error-message").classList.add("hidden")
    this.startValidation(file)
  }

  startValidation(file) {
    this.uploadmessageTarget.innerHTML = "Verificando..."
    this.progressTarget.innerHTML = ""
    this.stimulate(
      "AplazoSubmission#save_document",
      file.blob_id,
      this.validationValue,
      this.element.dataset.documentIndex,
      this.element.dataset.documentId,
    ).then((promise) => {
      const { payload } = promise
      if (
        payload &&
        Object.prototype.hasOwnProperty.call(payload, "valid") &&
        !payload["valid"]
      ) {
        this.error({}, payload.error)
      }
    }) // fetch(url).then(
    //   (response) => {
    //     if (!response.ok) {
    //       throw new Error('Network error');
    //     }
    //     return response.json();
    //   }
    // ).then(
    //   (validation_result) => {
    //     console.log(validation_result)
    //     if (validation_result["valid"]){
    //       this.successfulValidation()
    //     } else {
    //       this.error({}, validation_result.message)
    //     }
    //   }
    // ).catch(error => {
    //   this.successfulValidation()
    // })
  }

  successfulValidation(_response) {
    this.progressTarget.innerHTML = ""
    this.uploadmessageTarget.innerHTML = "Done"
    this.progressbadgeTarget.classList.remove("text-blue-800", "bg-blue-100")
    this.progressbadgeTarget.classList.add("text-green-800", "bg-green-100")
    this.spinnerTarget.classList.add("fa-regular", "fa-check")
    this.spinnerTarget.classList.remove(
      "fa-duotone",
      "fa-spinner-third",
      "fa-spin",
    )
  }

  error(_file, message) {
    this.dropZone.removeAllFiles()
    this.stimulate("AplazoSubmission#remove_document", message)
    // let uploadEl = this.findElement(this.element, ".dz-upload")
    // uploadEl.classList.remove("bg-blue-600")
    // uploadEl.classList.add("bg-teal-500")
    this.errorTarget.innerHTML = message
    // this.findElement(this.element, ".error-icon").style.display = "block";
    this.uploaderTarget.style.display = "block"
    this.progress_barTarget.style.display = "none"
    this.findElement(this.element, ".dropzone-msg").classList.add(
      "uploader-error",
    )
    this.findElement(this.element, ".error-message").classList.remove("hidden")
  }

  createDropZone(controller) {
    return new Dropzone(controller.element, {
      url: controller.url,
      headers: controller.headers,
      maxFiles: controller.maxFiles,
      maxFilesize: controller.maxFileSize,
      dictFileTooBig: controller.dictFileTooBig,
      dictInvalidFileType: controller.dictInvalidFileType,
      acceptedFiles: controller.acceptedFiles,
      addRemoveLinks: controller.addRemoveLinks,
      autoQueue: false,
      addedfile: this.addedFile.bind(this),
      success: this.successful.bind(this),
      error: this.error.bind(this),
    })
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = this.createDirectUpload(
      file,
      source.url,
      source,
      source.headers,
    )
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.file.blob_id = attributes["id"]
        this.file.blob_key = attributes["key"]
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    this.insertAfter(input, this.source.inputTarget)
    return input
  }

  insertAfter(el, referenceNode) {
    return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
  }

  removeElement(el) {
    if (el && el.parentNode) {
      el.parentNode.removeChild(el)
    }
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event),
    )
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = (event.loaded / event.total) * 100
    this.findElement(element, ".progressvalue").innerHTML = `${Math.round(
      progress,
    )}%`
  }

  findElement(root, selector) {
    if (typeof root == "string") {
      selector = root
      root = document
    }
    return root.querySelector(selector)
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }

  createDirectUpload(file, url, controller, _headers) {
    return new DirectUpload(file, url, controller, controller.headers)
  }
}
