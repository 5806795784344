import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["backdrop", "drawer"]

  connect() {
    this.open()
  }

  open() {
    enter(this.backdropTarget)
    enter(this.drawerTarget)
  }

  close() {
    Promise.all([leave(this.backdropTarget), leave(this.drawerTarget)]).then(
      () => {
        this.element.remove()
      },
    )
  }

  closeOnSubmit(e) {
    if (e.detail.success) {
      this.close()
    }
  }
}
