import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = { key: String, sandbox: Boolean, src: String }
  connect() {
    this.loadSyntageScript()
    super.connect()
  }

  disconnect() {
    window.removeEventListener("widgetScriptLoaded", this.initializeWidget)
  }

  loadSyntageScript() {
    if (!document.getElementById("satws-widget-id")) {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.id = "satws-widget-id"
      script.async = true
      script.src = this.srcValue
      script.setAttribute("data-key", this.keyValue)
      script.setAttribute("data-sandbox", this.sandboxValue)
      script.setAttribute("data-embed", "true")
      script.setAttribute("data-turbo-track", "reload")
      document.head.appendChild(script)
    }
  }

  initWidget() {
    try {
      this.initializeWidget()
    } catch {
      window.addEventListener("widgetScriptLoaded", this.initializeWidget)
    }
  }

  initializeWidget() {
    window.Satws.init()
    window.Satws.subscribe("registerWithSuccess", this.onSuccess.bind(this))
    window.Satws.subscribe("errorOnRegister", this.onError.bind(this))
  }

  submitForm() {
    const form = this.element.closest("form")
    form.submit()
  }

  onSuccess(_data) {
    const form = this.element.closest("form")
    form.querySelector('input[name="submission[tax_password]"]').value =
      "saved_on_syntage"
    this.submitForm()
  }

  onError(data) {
    console.log("error")
    console.log(data)
  }
}
