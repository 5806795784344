import { useMutation } from "stimulus-use"
import Dialog from "@stimulus-components/dialog"

export default class extends Dialog {
  connect() {
    useMutation(this, {
      element: this.dialogTarget,
      attributeFilter: ["result"],
      childList: true,
      subtree: true,
    })
    super.connect()
  }

  mutate(entries) {
    for (const mutation of entries) {
      if (
        mutation.type === "attributes" &&
        mutation.target.getAttribute("result") === "success"
      ) {
        this.forceClose()

        // We only render this input for some customers
        const form = document.querySelector("#submission-form")
        if (form != null) {
          form["selective-inputs"].toggle()
        }

        mutation.target.setAttribute("result", "none")
      }
    }
  }

  closeOnSubmit(e) {
    if (e.detail.success) {
      this.close()
    }
  }
}
