import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static values = {
    pattern: String,
    type: String,
    lazy: {
      type: Boolean,
      default: false,
    },
  }

  connect() {
    let params
    if (this.patternValue) {
      params = {
        mask: this.patternValue,
      }
    }
    if (this.typeValue == "currency") {
      params = {
        mask: "$num",
        lazy: false,
        blocks: {
          num: {
            mask: IMask.MaskedNumber,
            scale: 2,
            thousandsSeparator: ",",
            normalizeZeros: true,
            padFractionalZeros: true,
            radix: ".",
            min: 0,
          },
        },
      }
    } else if (this.typeValue == "url") {
      params = {
        mask: "https://url",
        lazy: false,
        blocks: {
          url: {
            mask: /^.*$/,
          },
        },
      }
    }
    this.mask = IMask(this.element, params)
  }

  disconnect() {
    this.mask?.destroy()
  }
}
