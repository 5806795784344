import { useMutation } from "stimulus-use"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = { id: String }
  connect() {
    this.loadMifielScript()
    this.initializeWidget()
    useMutation(this, {
      element: this.element.querySelector("#mifiel-widget"),
      childList: true,
    })
    super.connect()
  }

  disconnect() {
    window.removeEventListener("message", this.eventListener)
  }

  loadMifielScript() {
    this.initializeMifiel()
    if (!document.getElementById("mifiel-js")) {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.id = "mifiel-js"
      script.async = true
      script.src = "https://app.mifiel.com/sign-widget-v1.0.0.js?locale=es"
      script.setAttribute("data-turbo-track", "reload")
      document.head.appendChild(script)
    }
  }

  initializeMifiel() {
    window.mifiel = window.mifiel || []
    const methods = ["widget"]

    methods.forEach((method) => {
      if (!window.mifiel[method]) {
        window.mifiel[method] = (...args) => {
          window.mifiel.push([method, ...args])
        }
      }
    })
  }

  initializeWidget() {
    window.mifiel.widget({
      widgetId: this.idValue,
      appendTo: "mifiel-widget",
      chat: false,
      externalLinks: false,
      color: "e8483f",
      height: "930px",
      successBtnText: "Siguiente",
      onSuccess: {
        listener: (_data) => {
          this.stimulate("PrimaSubmission#document_signed")
        },
        callToAction: () => {
          this.submitForm()
        },
      },
      onError: {
        listener: (error) => {
          console.log(error)
        },
      },
    })
    this.eventListener = window.addEventListener(
      "message",
      this.handleMessage.bind(this),
    )
  }

  submitForm() {
    const form = this.element.closest("form")
    form.submit()
  }

  handleMessage(event) {
    if ("mifiel.widget.resize" === event.data.eventType) {
      const iframe = this.mifielIframe()
      if (event.data.height == "100vh") {
        iframe.style.maxHeight = "930px"
        return
      } else {
        iframe.style.maxHeight = null
      }
      if (iframe.style.height === event.data.height) return
      iframe.style.height = event.data.height
    }
  }

  mifielIframe() {
    return this.element.querySelector("#mifiel-sign-iframe")
  }

  mutate(entries) {
    for (const mutation of entries) {
      if (
        mutation.type === "childList" &&
        mutation.target.id === "mifiel-widget" &&
        mutation.addedNodes.length > 0
      ) {
        const iframe = mutation.addedNodes[0]
        if (iframe && iframe.id === "mifiel-sign-iframe") {
          this.mifielIframe().classList.add(
            "transition-[height]",
            "ease-in-out",
            "duration-300",
          )
        }
      }
    }
  }
}
