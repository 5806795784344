import { Application } from "@hotwired/stimulus"
import RevealController from "@stimulus-components/reveal"
import RailsNestedForm from "@stimulus-components/rails-nested-form"
import AutoSubmit from "@stimulus-components/auto-submit"
import Dropdown from "@stimulus-components/dropdown"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application
application.register("reveal", RevealController)
application.register("nested-form", RailsNestedForm)
application.register("auto-submit", AutoSubmit)
application.register("dropdown", Dropdown)

export { application }
