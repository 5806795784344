import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  static targets = ["tooltipContent"]

  connect() {
    this.tippyInstance = tippy(this.element, {
      content: this.tooltipContentTarget.innerHTML,
      allowHTML: true,
      theme: "light-border",
      arrow: true,
      interactive: true,
      placement: "right",
    })
  }

  disconnect() {
    this.tippyInstance.destroy()
  }
}
