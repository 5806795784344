import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  static targets = ["instance", "positionElem"]

  connect() {
    if (this.hasPositionElemTarget) {
      this.config["positionElement"] = this.positionElemTarget
    }
    super.connect()
  }
}
