import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "token"]
  static classes = ["hidden"]

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : "hidden"
    let el = this.element.querySelector(
      "input[name='submission[sales_channel]']:checked",
    )
    if (el) {
      this.toggleElements(el.value)
    } else {
      this.toggleElements("")
    }
    this.setHubspotToken()
  }

  toggleInput(e) {
    this.toggleElements(e.target.value)
  }

  toggleElements(type) {
    this.itemTargets.forEach((item) => {
      let values = item.dataset["aplazoValue"].split(",")
      if (values.includes(type)) {
        item.classList.remove(this.class)
      } else {
        item.classList.add(this.class)
      }
    })
  }

  setHubspotToken() {
    let hubspotUtk = this.getCookie("hubspotutk")
    this.tokenTarget.value = hubspotUtk
  }

  getCookie(name) {
    let value = "; " + document.cookie
    let parts = value.split("; " + name + "=")
    if (parts.length == 2) {
      return parts.pop().split(";").shift()
    }
  }
}
